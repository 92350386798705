.contacts-popup-modal .modal-content {
  background: #000;
  border-radius: 0;
  border: none;
}

.contacts-popup-modal {
  max-width: 1100px !important;
  width: 100% !important;
}

.contacts-popup {
  background: #000;
  padding: 40px;
  border: 1px solid rgba(133, 184, 255, 0.55);
}

.contacts-popup .form-wrap {
  width: 100%;
  max-width: 550px;
}

.contacts-popup .contacts-title {
  color: #fff;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.contacts-popup .contacts-desc {
  color: #98a2b3;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.contacts-popup .input {
  display: flex;
  margin: 30px 0px 16px 0px;
  align-self: stretch;
  border: none;
  border-bottom: 1px solid #667085;
  background: transparent;
  height: 80px;
  width: 100%;
  color: #fff;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.32px;
  align-items: center !important;
}

.contacts-popup .input div {
  display: flex;
  align-items: center;
}

.contacts-popup .input button {
  background: transparent;
  border: none;
  margin: auto;
}

.contacts-popup .input button:hover {
  background: transparent;
}

.contacts-popup .input ul {
  background-color: black !important;
  color: white !important;
  border: none;
  outline: none;
}

.contacts-popup .input input {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  background-color: transparent !important;
  color: #fff !important;
}

.contacts-popup .input input::placeholder {
  color: #fff;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.32px;
}

.contacts-popup .input input:focus {
  outline: none;
}

.contacts-popup .notification {
  animation: fadeInOut 3s ease-in-out;
  background-color: #f0f8ff;
  color: #333;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
}

.contacts-popup .button {
  color: #fff;
  font-family: Gilroy, sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 240px;
  height: 42px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #d0d5dd;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: transparent;
  transition: color 0.5s ease;
  margin-top: 40px;
}

.contacts-popup .button::before {
  content: "";
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  transition: bottom 0.5s ease-in-out;
}

.contacts-popup .button:hover {
  color: #000;
}

.contacts-popup .button:hover::before {
  bottom: 0;
}

.popup_logo {
  position: absolute;
  width: 340px;
  height: auto;
  top: 17%;
  right: 7%;
}

.close_text_block {
  position: absolute;
  right: 5%;
  top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.close_text {
  color: var(--Colors-Text-text-white, #FFF);
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.32px;
  margin: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 1200px) {
  .contacts-popup {
    padding: 40px;
    padding-top: 60px;
  }
  .contacts-popup-modal {
    max-width: 550px !important;
    width: 100% !important;
  }
  .contacts-popup .contacts-title {
    font-size: 28px;
  }
  .contacts-popup .contacts-desc {
    font-size: 14px;
  }
  .contacts-popup .input {
    width: 100%;
  }
  .popup_logo {
    display: none;
  }
  .contacts-popup .form-wrap {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 720px) {
  .modal.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .contacts-popup-modal {
    max-width: 100% !important;
    width: 100% !important;
  }
  .contacts-popup {
    padding: 30px;
    padding-top: 60px;
  }
  .contacts-popup .contacts-title {
    font-size: 24px;
  }
  .contacts-popup .form-wrap {
    width: 100%;
    max-width: 100%;
  }
  .contacts-popup .input {
    display: flex;
    margin: 10px 0px 16px 0px;
    height: 50px;
    border-radius: 0;
  }
  .close_text_block {
    position: absolute;
    right: 5%;
    top: 3%;
  }
}

